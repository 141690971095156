import React from "react";
import { Link } from "react-router-dom";
import svg1 from "../../assets/svg/result.svg";
import svg2 from "../../assets/svg/rocket.svg";
import svg3 from "../../assets/svg/team.svg";
import svg4 from "../../assets/svg/quality.svg";
import svg5 from "../../assets/svg/influncers.svg";
import svg6 from "../../assets/svg/datadriven.svg";
import { FaArrowRight } from "react-icons/fa";


export default function whywe() {
  return (
    <section className="relative md:py-24 pt-16 px-9 flex justify-center">
      <div className="container relative ">
        <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px] relative">
          <div className="lg:col-span-8 md:col-span-12">
            <div className="grid grid-cols-1 pb-8">
              <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
                Why We Are The
                <p>Best Influencer Marketing Platform in Tamil Nadu</p>
              </h3>

              {/* <p className="text-slate-400 max-w-xl">

              </p> */}
            </div>

            <div className="grid md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
              <div className="px-6 py-10 shadow hover:shadow-md  hover:shadow-gray-700 duration-500 rounded-2xl bg-white ">
                <img src={svg1} alt="" />
                <div className="content mt-7">
                  <Link
                    to=""
                    className="title h5 text-lg font-medium hover:text-indigo-600"
                  >
                    Campaign Execution
                  </Link>
                  <p className="text-slate-400 mt-3">
                    Personalized and customized influencer marketing campaign
                    execution
                  </p>

                  <div className="mt-5">
                    <Link
                      to=""
                      className="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out"
                    >
                      <FaArrowRight />
                    </Link>
                  </div>
                </div>
              </div>

              <div className="px-6 py-10 shadow hover:shadow-md  hover:shadow-gray-700 duration-500 rounded-2xl bg-white ">
                <img src={svg2} alt="" />
                <div className="content mt-7">
                  <Link
                    to=""
                    className="title h5 text-lg font-medium hover:text-indigo-600"
                  >
                    Highly Experienced
                  </Link>
                  <p className="text-slate-400 mt-3">
                    More than more then 6+ years of experience working with
                    Youtubers and Instagrammers
                  </p>

                  <div className="mt-5">
                    <Link
                      to=""
                      className="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out"
                    >
                      <FaArrowRight />
                    </Link>
                  </div>
                </div>
              </div>

              <div className="px-6 py-10 shadow hover:shadow-md  hover:shadow-gray-700 duration-500 rounded-2xl bg-white ">
                <img src={svg3} alt="" />

                <div className="content mt-7">
                  <Link
                    to=""
                    className="title h5 text-lg font-medium hover:text-indigo-600"
                  >
                    Dedicated Team
                  </Link>
                  <p className="text-slate-400 mt-3">
                    Dedicated team for devising the comprehensive influencer
                    marketing strategy
                  </p>

                  <div className="mt-5">
                    <Link
                      to=""
                      className="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out"
                    >
                      <FaArrowRight />
                    </Link>
                  </div>
                </div>
              </div>

              <div className="px-6 py-10 shadow hover:shadow-md  hover:shadow-gray-700 duration-500 rounded-2xl bg-white ">
                <img src={svg4} alt="" />
                <div className="content mt-7">
                  <Link
                    to=""
                    className="title h5 text-lg font-medium hover:text-indigo-600"
                  >
                    Utmost Quality
                  </Link>
                  <p className="text-slate-400 mt-3">
                    Utmost quality and valuable content delivery through content
                    creators
                  </p>

                  <div className="mt-5">
                    <Link
                      to=""
                      className="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out"
                    >
                      <FaArrowRight />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="lg:col-span-4 md:col-span-12">
            <div className="grid lg:grid-cols-1 md:grid-cols-2 grid-cols-1 gap-[30px]">
              <div className="px-6 py-10 shadow hover:shadow-md  hover:shadow-gray-700 duration-500 rounded-2xl bg-white ">
                <img src={svg5} alt="" />
                <div className="content mt-7">
                  <Link
                    to=""
                    className="title h5 text-lg font-medium hover:text-indigo-600"
                  >
                    Top Influencers
                  </Link>
                  <p className="text-slate-400 mt-3">
                    2 Lakh+ Top social media influencers are the part of
                    Bookmesocial
                  </p>

                  <div className="mt-5">
                    <Link
                      to=""
                      className="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out"
                    >
                      <FaArrowRight />
                    </Link>
                  </div>
                </div>
              </div>

              <div className="px-6 py-10 shadow hover:shadow-md  hover:shadow-gray-700 duration-500 rounded-2xl bg-white ">
                <img src={svg6} alt="" />
                <div className="content mt-7">
                  <Link
                    to=""
                    className="title h5 text-lg font-medium hover:text-indigo-600"
                  >
                    Data Driven
                  </Link>
                  <p className="text-slate-400 mt-3">
                    We utilize data driven approach and leverage people powered
                    content, referrals and marketing
                  </p>

                  <div className="mt-5">
                    <Link
                      to=""
                      className="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out"
                    >
                      <FaArrowRight />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="overflow-hidden after:content-[''] after:absolute after:h-40 after:w-40 after:bg-red-600/5 after:top-2/4 after:start-0 after:-z-1 after:rounded-3xl after:animate-[spin_10s_linear_infinite]" style={{ zIndex: -1 }}></div>
          <div className="overflow-hidden after:content-[''] after:absolute after:h-[512px] after:w-[512px] after:bg-indigo-600/5 after:top-1/4 after:end-0 after:-z-1 after:rounded-full" style={{ zIndex: -1 }}></div>
        </div>
      </div>
    </section>
  );
}
