import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import img1 from "../../assets/auth/partner_bg.png";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL } from "../../constant/constant";
import { FiX } from "react-icons/fi";

export default function Creatorregister() {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    mobile_number: "",
    email_id: "",
    channel_site_name: "",
    social_media: [],
    content_types: [""],
    followers_range: "",
    password_creator: "",
    message: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleterms_condision_change = () => {
    setIsChecked(!isChecked);
  };

  const handleCheckboxChange = (socialMediaOption) => {
    const updatedSocialMedia = formData.social_media.includes(socialMediaOption)
      ? formData.social_media.filter((media) => media !== socialMediaOption)
      : [...formData.social_media, socialMediaOption];

    setFormData({
      ...formData,
      social_media: updatedSocialMedia,
    });
  };


  const handleContentTypeChange = (index, value) => {
    const updatedContentTypes = [...formData.content_types];
    updatedContentTypes[index] = value;
    setFormData((prevData) => ({
      ...prevData,
      content_types: updatedContentTypes,
    }));
  };

  const addContentType = () => {
    setFormData((prevData) => ({
      ...prevData,
      content_types: [...prevData.content_types, ""],
    }));
  };

  const removeContentType = (index) => {
    const updatedContentTypes = formData.content_types.filter(
      (_, i) => i !== index
    );
    setFormData((prevData) => ({
      ...prevData,
      content_types: updatedContentTypes,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // List of required fields
    const requiredFields = ["name", "mobile_number", "email_id", "channel_site_name", "followers_range", "password_creator"];

    // Check if any required field is empty
    const isFormValid = requiredFields.every((field) => formData[field].trim() !== "");

    if (!isFormValid) {
      toast.error("Please fill in all required fields", { autoClose: 3000 });
      return;
    }

    try {
      if (isChecked) {
        const response = await axios.post(
          `${API_URL}/api/creatorreg/Register_cre_influ`,
          formData
        );
        if (response.status === 200) {
          toast.success(response.data.message, { autoClose: 3000 });
          setFormData({
            name: "",
            mobile_number: "",
            email_id: "",
            channel_site_name: "",
            social_media: [],
            content_types: [""],
            followers_range: "",
            password_creator: "",
            message: "",
          });
          setIsChecked(false);

          navigate("/dashboard");
        }
      } else {
        toast.info("Please accept our terms and conditions", { autoClose: 3000 });
      }
    } catch (error) {
      toast.error(error.response?.data?.error || "Registration failed", { autoClose: 3000 });
      console.error("Error during registration:", error.message);
    }
  };


  return (
    <div>
      <section className="relative flex justify-center w-full py-24 overflow-hidden bg-gradient-to-b to-transparent from-indigo-600/20 dark:from-indigo-600/40">
        <ToastContainer />
        <Fade cascade>
          <div className="container relative">
            <div className="relative grid md:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px]">
              <div className="md:col-span-6">
                <div className="md:me-8">
                  <img src={img1} alt="" />
                  <h2 className="font-bold text-center lg:leading-normal leading-normal text-3xl lg:text-4xl mb-5 text-black relative">
                    Smarter way to generate Revenue
                  </h2>
                  <p className="text-slate-400 text-lg max-w-xl">
                    Are you struggling to generate revenue for your accounts? We
                    help you with generating revenue based on your audience
                    count and audience type. All you need is to be a Creator
                    with us.
                  </p>
                </div>
              </div>
              <div className="md:col-span-6 gap-5">
                <div className="relative border-[3px] text-gray-700 text-center  border-gray-100  rounded-lg">
                  <h3 className="font-bold p-4 text-2xl">
                    SignUp for Become our Creator/Influencer !
                  </h3>
                  <form action="">
                    <div className="p-2">
                      <input
                        type="text"
                        id="name"
                        className="py-4 pe-20 ps-6 h-[50px] outline-none text-black rounded-md bg-white w-full shadow required"
                        placeholder="Name*"
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="p-2">
                      <input
                        type="number"
                        id="mobile_number"
                        className="py-4 pe-20 ps-6 h-[50px] outline-none text-black rounded-md bg-white w-full shadow required"
                        maxLength="13"
                        pattern="[0-9]+"
                        option="None"
                        placeholder="Mobile Number*"
                        value={formData.mobile_number}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="p-2">
                      <input
                        type="email"
                        id="email_id"
                        className="py-4 pe-20 ps-6 h-[50px] outline-none text-black rounded-md bg-white w-full shadow required"
                        placeholder="Email ID*"
                        value={formData.email_id}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="p-2">
                      <input
                        type="text"
                        id="channel_site_name"
                        className="py-4 pe-20 ps-6 h-[50px] outline-none text-black rounded-md bg-white w-full shadow required"
                        placeholder="Channel / Site Name*"
                        value={formData.channel_site_name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-4">
                      <div className="p-2 col-lg-3 flex ">
                        <input
                          type="checkbox"
                          id="facebook"
                          className="py-4 pe-20 ps-6 h-[20px] w-4 outline-none  shadow required"
                          checked={formData.social_media.includes("facebook")}
                          onChange={() => handleCheckboxChange("facebook")}
                        />
                        <label htmlFor="facebook" className="pl-1">
                          Facebook
                        </label>
                      </div>
                      <div className="p-2 col-lg-3 flex ">
                        <input
                          type="checkbox"
                          id="instagram"
                          className="py-4 pe-20 ps-6 h-[20px] w-4 outline-none shadow required"
                          checked={formData.social_media.includes("instagram")}
                          onChange={() => handleCheckboxChange("instagram")}
                        />
                        <label htmlFor="instagram" className="pl-1">
                          Instagram
                        </label>
                      </div>
                      <div className="p-2 col-lg-3 flex ">
                        <input
                          type="checkbox"
                          id="youtube"
                          className="py-4 pe-20 ps-6 h-[20px] w-4 outline-none shadow required"
                          checked={formData.social_media.includes("youtube")}
                          onChange={() => handleCheckboxChange("youtube")}
                        />
                        <label htmlFor="youtube" className="pl-1">
                          Youtube
                        </label>
                      </div>
                      <div className="p-2 col-lg-3 flex ">
                        <input
                          type="checkbox"
                          id="others"
                          className="py-4 pe-20 ps-6 h-[20px] w-4 outline-none shadow required"
                          checked={formData.social_media.includes("others")}
                          onChange={() => handleCheckboxChange("others")}
                        />
                        <label htmlFor="others" className="pl-1">
                          Others
                        </label>
                      </div>
                    </div>
                    {formData.content_types && (
                      formData.content_types.map((contentType, index) => (
                        <div className="p-2 flex items-center" key={index}>
                          <input
                            type="text"
                            className="py-4 pe-20 ps-6 h-[50px] outline-none text-black rounded-md bg-white w-full shadow required"
                            placeholder="Content Type*"
                            value={contentType}
                            onChange={(e) =>
                              handleContentTypeChange(index, e.target.value)
                            }
                          />
                          {index > 0 && (
                            <button
                              type="button"
                              className="ml-2 text-red-500"
                              onClick={() => removeContentType(index)}
                            >
                              <FiX size={24} />
                            </button>
                          )}
                        </div>
                      ))
                    )}
                    <div className="p-2 flex justify-end">
                      <button
                        type="button"
                        className="bg-indigo-600 text-white px-2 py-3 font-semibold rounded-xl duration-500 hover:bg-indigo-700"
                        onClick={addContentType}
                      >
                        + Add Content Type
                      </button>
                    </div>

                    <div className="p-2">
                      <input
                        type="text"
                        id="followers_range"
                        className="py-4 pe-20 ps-6 h-[50px] outline-none text-black rounded-md bg-white w-full shadow required"
                        placeholder="Enter Followers Range*"
                        value={formData.followers_range}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="p-2">
                      <input
                        type="text"
                        id="code"
                        className="py-4 pe-20 ps-6 h-[50px] outline-none text-black rounded-md bg-white w-full shadow required"
                        placeholder="Enter Coupon Code"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="p-2">
                      <input
                        type="password"
                        id="password_creator"
                        className="py-4 pe-20 ps-6 h-[50px] outline-none text-black rounded-md bg-white w-full shadow required"
                        placeholder="Password*"
                        value={formData.password_creator}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="p-2">
                      <textarea
                        id="message"
                        className="py-4 pe-20 ps-6 h-[125px] outline-none text-black rounded-md bg-white w-full shadow required"
                        placeholder="Message"
                        rows="5"
                        value={formData.message}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="flex justify-left pl-4 gap-2">
                      <input
                        type="checkbox"
                        id="myCheckbox"
                        checked={isChecked}
                        onChange={handleterms_condision_change}
                      />
                      <label htmlFor="myCheckbox">
                        I Hereby Accept the
                        <a
                          href="/docs/Terms_and_Conditions.pdf"
                          download
                          className="mx-1 text-indigo-600 underline"
                        >
                          Terms & Conditions
                        </a>
                        and
                        <a
                          href="/docs/Privacy_Policy.pdf"
                          download
                          className="ml-1 text-indigo-600 underline"
                        >
                          Privacy Policy
                        </a>
                      </label>
                      <br />
                    </div>
                    <div className="p-4 flex justify-center">
                      <button
                        type="submit"
                        id="submitsubscribe"
                        name="send"
                        onClick={handleSubmit}
                        className="py-2 px-2 inline-block font-semibold tracking-wide border align-middle duration-500 text-[18px] text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-full w-[120px]"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </section>
    </div>
  );
}