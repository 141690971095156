import React from "react";
import imageSrc from "../../assets/images/r45p97po_top-10-influencers-from-chennai_625x300_18_August_23 (1).webp";

export default function Aboutbookmesocial() {
  return (
    <section className="relative w-full flex py-2 md:px-22 justify-center ">
      <div className="container flex items-center relative mt-24">
        <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
          <div className="md:col-span-6">
            <div className="lg:me-8">
              <img
                src={imageSrc}
                alt=""
                className="max-w-full h-auto rounded-lg"
              />
            </div>
          </div>

          <div className="col-span-6">
            <div className="lg:ms-5">
              <h6 className="text-indigo-600 text-sm font-bold uppercase mb-2">
                About Bookme Social
              </h6>
              <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
                Why Choose India’s Top <br />
                Influencer Marketing Agency?
              </h3>

              <p className="text-slate-400 max-w-xl mb-6">
                The top goals of influencer marketing for businesses comprise
                increasing brand awareness, reaching new audiences, new product
                launches, and generating sales and conversions.
                <br />
                Understanding client’s goals and objectives are vital for
                designing an effective campaign. Leveraging the power of
                influencers, brands can reach niche-specific audiences.
              </p>


            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
