import React from 'react';
import { Link } from 'react-router-dom';
import { Fade } from "react-awesome-reveal";
import { useTypewriter, Cursor } from 'react-simple-typewriter';
import img2 from '../../assets/images/top-micro-influencer-marketing-agency-in-india (1).webp';
import logo from '../../assets/Yolostarts.png';

export default function Helpingbrands() {

  const [typeEffect] = useTypewriter({
    words: ['Creators', 'Influencers', 'Contracts', 'Partnerships'],
    loop: {},
    typeSpeed: 80,
    deleteSpeed: 40,
    delaySpeed: 1000,
  });

  return (
    <section className="relative w-full flex pb-24 lg:py-24 md:px-22 justify-center bg-gradient-to-br from-indigo-600/20 to-yellow-500/20 dark:from-indigo-600/20 dark:to-yellow-500/20">
      <Fade cascade>
        <div className="container relative">
          {/* <div className='flex justify-center'>
            <Link to="/yolastarz">
              <img
                src={logo}
                alt=""
                className='h-32 w-auto'
              />
            </Link>
          </div> */}
          <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-5 gap-[30px]">
            <div className="lg:col-span-7">
              <div className="me-4">
                <h4 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-[44px] my-5 text-black">
                  Platform for Hassle-Free Collaboration with
                  <span className='text-indigo-600 ml-3'>{typeEffect}</span>
                </h4>
                <p className="text-slate-500 text-lg max-w-xl">
                  It's Simple, Flexible, and Powerful. We've built out one of the
                  most advanced end-to-end managed service solutions to help
                  push consumers through all stages of the sales cycle and drive
                  ROI.
                </p>
                <p className='text-red-500 pt-4 font-bold'>Signup Here</p>

                <div className="mt-3">
                  <Link
                    to="/brandregister"
                    data-type="youtube"
                    data-id=""
                    className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-full"
                  >
                    Brands
                  </Link>
                  <li className="inline-block m-0.5">
                    <Link
                      to="/creatorregister"
                      className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-red-700 text-white rounded-full"
                    >
                      Influencers
                    </Link>
                  </li>
                </div>
              </div>
            </div>

            <div className="lg:col-span-5 relative flex items-center justify-center">
              <img
                src={img2}
                className="border-[3px] border-gray-100 rounded-lg"
                alt=""
              />
            </div>
          </div>
        </div>
      </Fade>
    </section>
  );
}