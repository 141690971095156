import React from 'react';
import img1 from "../../assets/images/about/ab03.jpg";
import img2 from "../../assets/images/about/ab02.jpg";
// import img3 from"../../assets/images/about/ab01.jpg";
// import img4 from"../../assets/images/client/amazon.svg";
// import img5 from"../../assets/images/client/google.svg";
// import img6 from"../../assets/images/client/amazon.svg";
// import img7 from"../../assets/images/client/paypal.svg";
// import img8 from"../../assets/images/client/shopify.svg";
// import img9 from"../../assets/images/client/shopify.svg";
import Youtube from "../../assets/images/youtube.jpg"
import { Link } from 'react-router-dom';




export default function Whoarewe() {
    return (
        <div>
            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                        <div className="lg:col-span-5 md:col-span-6">
                            <div className="grid grid-cols-12 gap-6 items-center">
                                <div className="col-span-6">
                                    <div className="grid grid-cols-1 gap-6">
                                        <img src={img1} className="shadow rounded-md" alt="" />
                                        <img src={img2} className="shadow rounded-md" alt="" />
                                    </div>
                                </div>

                                <div className="col-span-6">
                                    <div className="grid grid-cols-1 gap-6">
                                        <img src={Youtube} className="shadow rounded-md" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="lg:col-span-7 md:col-span-6">
                            <div className="lg:ms-5">
                                {/* <div className="flex mb-4">
                                <span className="text-indigo-600 text-2xl font-bold mb-0"><span className="counter-value text-6xl font-bold" data-target="6">5</span>+</span>
                                <span className="self-end font-medium ms-2">Years <br/> Experience</span>
                            </div> */}

                                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Who we are ?</h3>

                                <p className="text-slate-400 max-w-xl">Bookmesocial is the best Influencer marketing agency in India which provides the top influencer marketing platform to help brands / visionary marketers leverage social media influencers’ content in marketing the product/service.</p>

                                <div className="mt-6">
                                    <Link to="/contactus" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-full me-2 mt-2"><i className="uil uil-envelope">
                                    </i> Contact us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="container relative mt-8">
                <div className="grid md:grid-cols-6 grid-cols-2 justify-center gap-[30px]">
                    <div className="mx-auto mt-8">
                        <img src={img4} className="h-6" alt=""/>
                    </div>

                    <div className="mx-auto mt-8">
                        <img src={img5} className="h-6" alt=""/>
                    </div>
                    
                    <div className="mx-auto mt-8">
                        <img src={img6} className="h-6" alt=""/>
                    </div>
                    
                    <div className="mx-auto mt-8">
                        <img src={img7} className="h-6" alt=""/>
                    </div>
                    
                    <div className="mx-auto mt-8">
                        <img src={img8} className="h-6" alt=""/>
                    </div>
                    
                    <div className="mx-auto mt-8">
                        <img src={img9} className="h-6" alt=""/>
                    </div>
                </div>
            </div> */}

            </section>
        </div>
    )
}
