import React, { useState } from "react";
import { Fade } from "react-awesome-reveal";
import img1 from "../../assets/auth/brand_bg.png";
import { CountryDropdown } from "react-country-region-selector";
import axios from "axios";
import { API_URL } from "../../constant/constant";
import { toast } from "react-toastify";

export default function Brandregister() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [formData, setFormData] = useState({
    company_name: "",
    company_industry_type: "",
    contact_person_name: "",
    job_title: "",
    website_link: "",
    brand_or_agent: "",
    country: "",
    email_id: "",
    mobile_number: "",
    password: "",
  });

  const [formErrors, setFormErrors] = useState({
    company_name: false,
    company_industry_type: false,
    contact_person_name: false,
    job_title: false,
    website_link: false,
    brand_or_agent: false,
    country: false,
    email_id: false,
    mobile_number: false,
    password: false,
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    setFormErrors({
      ...formErrors,
      [e.target.id]: false,
    });
  };

  const handleCountryChange = (val) => {
    setFormData({
      ...formData,
      country: val,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        errors[key] = true;
      }
    });

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    axios
      .post(`${API_URL}/api/brandreg/brand_regestration`, formData)
      .then((response) => {
        toast.success(response.data.message)
        setIsModalOpen(true);
        setTimeout(() => {
          setIsModalOpen(false);
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
  };


  return (
    <div>
      <section className="relative flex justify-center w-full py-24 overflow-hidden bg-gradient-to-b to-transparent from-indigo-600/20 dark:from-indigo-600/40">
        <Fade cascade>
          <div className="container relative">
            <div className="relative grid md:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px]">
              <div className="md:col-span-6">
                <div className="md:me-8">
                  <img src={img1} alt="" />
                  <h2 className="font-bold text-center lg:leading-normal leading-normal text-3xl lg:text-4xl mb-5 text-black relative">
                    Advertise your Brand
                  </h2>
                  <p className="text-slate-400 text-lg max-w-xl">
                    Choosing the audiences is the most important part in
                    advertising your brand. Showcasing your brand to wrong
                    people is always a waste in resource. We help you in
                    publishing your brand to the right audience.
                  </p>
                </div>
              </div>
              <div className="md:col-span-6 gap-5">
                <div className="relative border-[3px] text-gray-700 text-center  border-gray-100  rounded-lg">
                  <h3 className="font-medium p-4">
                    Please fill with your details
                  </h3>
                  <form onSubmit={handleSubmit}>
                    <div className="p-2">
                      <input
                        type="text"
                        id="company_name"
                        className="py-4 pe-20 ps-6 h-[50px] outline-none text-black rounded-md bg-white w-full shadow required"
                        placeholder="Company Name*"
                        value={formData.company_name}
                        onChange={handleChange}
                      />
                      {formErrors.company_name && (
                        <p className="text-red-500">
                          Company Name cannot be empty
                        </p>
                      )}
                    </div>
                    <div className="p-2">
                      <input
                        type="text"
                        id="company_industry_type"
                        className="py-4 pe-20 ps-6 h-[50px] outline-none text-black rounded-md bg-white w-full shadow required"
                        placeholder="Company Industry Type*"
                        value={formData.company_industry_type}
                        onChange={handleChange}
                      />
                      {formErrors.company_industry_type && (
                        <p className="text-red-500">
                          company industry type cannot be empty
                        </p>
                      )}
                    </div>
                    <div className="p-2">
                      <input
                        type="text"
                        id="contact_person_name"
                        className="py-4 pe-20 ps-6 h-[50px] outline-none text-black rounded-md bg-white w-full shadow required"
                        placeholder="Contact Person Name*"
                        value={formData.contact_person_name}
                        onChange={handleChange}
                      />
                      {formErrors.contact_person_name && (
                        <p className="text-red-500">
                          Contact Person Name cannot be empty
                        </p>
                      )}
                    </div>
                    <div className="p-2">
                      <input
                        type="text"
                        id="job_title"
                        className="py-4 pe-20 ps-6 h-[50px] outline-none text-black rounded-md bg-white w-full shadow required"
                        placeholder="Job Title (designation)*"
                        value={formData.job_title}
                        onChange={handleChange}
                      />
                      {formErrors.job_title && (
                        <p className="text-red-500">
                          Job Title cannot be empty
                        </p>
                      )}
                    </div>
                    <div className="p-2">
                      <input
                        type="url"
                        id="website_link"
                        className="py-4 pe-20 ps-6 h-[50px] outline-none text-black rounded-md bg-white w-full shadow required"
                        placeholder="Website Link (https://www.example.com)*"
                        value={formData.website_link}
                        onChange={handleChange}
                      />
                      {formErrors.website_link && (
                        <p className="text-red-500">
                          Website Link cannot be empty
                        </p>
                      )}
                    </div>
                    <div className="p-2">
                      <select
                        name="Brand or Agent"
                        id="brand_or_agent"
                        className="py-4 pe-20 ps-6 h-[50px] outline-none text-black rounded-md bg-white w-full shadow required"
                        value={formData.brand_or_agent}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          Choose your Brand or Agency
                        </option>
                        <option value="Brand">Brand</option>
                        <option value="Agent">Agency</option>
                      </select>
                    </div>
                    <div className="p-2">
                      <CountryDropdown
                        value={formData.country}
                        onChange={handleCountryChange}
                        className="py-4 pe-20 ps-6 h-[50px] outline-none text-black rounded-md bg-white w-full shadow required"
                      />
                    </div>
                    <div className="p-2">
                      <input
                        type="email"
                        id="email_id"
                        className="py-4 pe-20 ps-6 h-[50px] outline-none text-black rounded-md bg-white w-full shadow required"
                        placeholder="Email ID*"
                        value={formData.email_id}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="p-2">
                      <input
                        type="number"
                        id="mobile_number"
                        className="py-4 pe-20 ps-6 h-[50px] outline-none text-black rounded-md bg-white w-full shadow required"
                        maxLength="13"
                        pattern="[0-9]+"
                        placeholder="Mobile Number*"
                        value={formData.mobile_number}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="p-2">
                      <input
                        type="password"
                        id="password"
                        className="py-4 pe-20 ps-6 h-[50px] outline-none text-black rounded-md bg-white w-full shadow required"
                        placeholder="Password*"
                        value={formData.password}
                        onChange={handleChange}
                      />
                    </div>

                    <div class=" p-4 flex justify-center">
                      <input
                        type="submit"
                        id="submitsubscribe"
                        name="brandreg"
                        className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md w-52 cursir cursor-pointer"
                      />
                    </div>
                    {isModalOpen && (
                      <div className="fixed inset-0 z-50 flex items-center justify-center">
                        <div className="absolute inset-0 bg-gray-800 opacity-50"></div>
                        <div className="bg-white p-8 rounded-md shadow-md z-10">
                          <p className="text-2xl font-semibold mb-4">
                            We will verify your links and get back to you within
                            7 working days.Thanks for showing your interest
                          </p>
                          <button
                            className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
                            onClick={() => {
                              setIsModalOpen(false);
                              window.location.reload();
                            }}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </section>
    </div>
  );
}
