import React from "react";
import Influencertab from "./Influencertab";

export default function Brandtab() {

  return (
    <>
      <section className="realtive lg:pt-24 pt-16 bg-gray-50 dark:bg-slate-800">
        <div className="container relative">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
              We help brand communication go viral by utilizing KOLs and
              influencers in marketing in just 3 steps:-
            </h3>

            <p className="text-slate-400 max-w-xl mx-auto">
              How We Design the Influencer Marketing Strategy
            </p>
            <div className="flex justify-center">
              <Influencertab />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
