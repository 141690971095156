import React from "react";
import { Fade } from "react-awesome-reveal";
import img1 from "../../assets/auth/partner_bg.png";

export default function Brandlogin() {
  return (
    <div>
      <section className="relative flex justify-center w-full py-24 overflow-hidden bg-gradient-to-b to-transparent from-indigo-600/20 dark:from-indigo-600/40">
        <Fade cascade>
          <div className="container relative">
            <div className="relative grid md:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px]">
              <div className="md:col-span-6">
                <div className="md:me-8">
                  <img src={img1} alt="" />
                  <h2 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 text-black dark:text-white relative">
                    Smarter way to generate Revenue
                  </h2>
                  <p className="text-slate-400 text-lg max-w-xl">
                    Are you struggling to generate revenue for your accounts? We
                    help you with generating revenue based on your audience
                    count and audience type. All you need is to be a Creator
                    with us.
                  </p>
                </div>
              </div>
              <div className="md:col-span-6 gap-5">
                <div className="relative border-[3px] border-gray-100  rounded-lg">
                  <div className="p-4">
                    <select
                      name=""
                      id=""
                      className="py-4 pe-20 ps-6 h-[50px] outline-none text-black rounded-md bg-white shadow "
                    >
                      <option value="">Login as ?</option>
                      <option value="1">Brand/Agent</option>
                      <option value="0">Creators/Influencers</option>
                      <option value="0">Partners/Contractors</option>
                    </select>
                    <div className="mt-4">
                      <input
                        type="text"
                        id="subscribe"
                        name="email"
                        className="py-4 pe-1 ps-2 w-full h-[50px] outline-none text-black dark:text-white rounded-md bg-white shadow placeholder:text-sm placeholder:w-full"
                        placeholder="Mobile Number/Email/Username"
                      />
                    </div>
                    <div className="mt-4">
                      <input
                        type="password"
                        id="password"
                        className="py-4 pe-1 ps-2 w-full h-[50px] outline-none text-black dark:text-white rounded-md bg-white  shadow "
                        placeholder="Password"
                      />
                    </div>
                    <div class="pt-2 flex justify-center">
                      <button
                        type="submit"
                        id="submitsubscribe"
                        name="send"
                        className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-full"
                      >
                        Login
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </section>
    </div>
  );
}
