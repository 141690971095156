import React, { useState, useEffect } from 'react';
import Explore from './Home/Exploreinfluncers';
import Helpingbrand from './Home/Helpingbrands';
import Brandcampaign from './Home/Brandcampaign';
import Aboutbookmesocial from './Home/Aboutbookmesocial';
import Whyme from './Home/whywe';
import Topgoals from './Home/Topgoals';
import Ourservices from './Home/Ourservices';
import Testimonal from './Home/Testimonal';
import Faq from './Home/Faq';
import { Link } from 'react-router-dom';
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
// import img2 from '../assets/200w.gif';

function Home() {
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setModalOpen(true);
  }, []);

  function closeModalFunction() {
    setModalOpen(false);
  }

  return (
    <div>
      <Helpingbrand />
      <Brandcampaign />
      <Aboutbookmesocial />
      <Whyme />
      <Topgoals />
      <Explore />
      {/* {isModalOpen &&
        <div className="modal overflow-y-auto flex flex-col overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center  inset-0 max-h-full bg-opacity-60 p-8">
          <div className="modal-content  sm:w-96 md:w-3/4 lg:w-2/3 max-w-screen-md mx-auto flex flex-col justify-center items-center text-center bg-indigo-600  p-8">
            <button
              onClick={() => closeModalFunction ()}
              className="absolute top-4 right-6 text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>

            <div className=" grid md:grid-cols-2 gap-2">
              <div >
                <img
                  src={img2}
                  className="hidden sm:block md:w-2/3 lg:w-2/3 xl:w-1/2 mx-auto mb-8"
                  alt="Description "
                />
              </div>
              <div className=" items-center justify-center flex ">
                <div>
                  <p className=" text-xl md:text-lg lg:text-xl font-bold text-white">
                    For Brands and Agency : <br /> Say, Goodbye for Boring Ads
                  </p>
                  <p className="text-sm md:text-sm lg:text-base text-center font-bold p-2 text-white">
                    Hi, Are you an Influencer or Creator in Social Media?
                    {' '}
                    <br />
                    {' '}
                    You
                    need additional income with campaign collaborations?
                    {' '}
                    <br />
                    {' '}
                    It’s
                    the right place for your passion & work.
                    {' '}
                    <br />
                    {' '}
                    We accept all
                    counts happily. Register your profile here <br />
                  </p>
                  <div className="mt-4 md:mt-6">
                    <Link
                      to="/creatorregister"
                      className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-sm md:text-base lg:text-lg text-center bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 text-white rounded-full"
                    >
                      Influencers / Creator
                    </Link>
                  </div>
                  <div className="font-bold text-white mt-3 text-sm md:text-sm lg:text-base">
                    Join our community with{' '}
                    <span className="font-black text-sm md:text-base lg:text-lg">
                      9,000 partners
                    </span>
                    {' '}
                    & Growing Counts
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>} */}

      <Ourservices />
      <Testimonal />
      <Faq />
      <div><WhatsAppWidget phoneNumber="+919600810030" /> </div>
    </div>
  );
}

export default Home;
