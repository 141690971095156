import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

export default function exploreinfluncers() {
  return (
    <section className="relative md:py-24 py-16">
      <div className="container relative">
        <div className="grid grid-cols-1 pb-8 text-center">
          <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
            Explore Influencers Categories
          </h3>
          <p className="text-slate-400 max-w-xl mx-auto">
            Our influencer marketing experts unite brands with top content
            creators, social media influencers, artists, celebrities, vloggers
            and bloggers with interested viewers (brand’s potential customers)
            for executing award winning influencer marketing campaigns.
          </p>
        </div>

        <div className="flex justify-center items-center text-center mt-8">
          <ul className="list-none space-x-3 space-y-4">
            <motion.li
              className="inline-block"
              whilehover={{ scale: 1.1 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
              <Link
                href="#"
                className="group flex items-center bg-white dark:bg-slate-900 hover:text-indigo-600 shadow hover:shadow-md dark:shadow-gray-800 hover:dark:shadow-gray-800 border-4 border-double border-gray-100 hover:border-indigo-600/30 dark:border-gray-800 hover:dark:border-indigo-600/50 py-1.5 px-4 rounded-full align-middle duration-500"
              >
                <i className="uil uil-airplay me-2 text-[18px]"></i>
                <span className="text-[18px] font-medium">Art</span>
              </Link>
            </motion.li>

            <motion.li
              className="inline-block"
              whilehover={{ scale: 1.1 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
              <Link
                href="#"
                className="group flex items-center bg-white dark:bg-slate-900 hover:text-indigo-600 shadow hover:shadow-md dark:shadow-gray-800 hover:dark:shadow-gray-800 border-4 border-double border-gray-100 hover:border-indigo-600/30 dark:border-gray-800 hover:dark:border-indigo-600/50 py-1.5 px-4 rounded-full align-middle duration-500"
              >
                <i className="uil uil-at me-2 text-[18px]"></i>
                <span className="text-[18px] font-medium">Comedy</span>
              </Link>
            </motion.li>

            <motion.li
              className="inline-block"
              whilehover={{ scale: 1.1 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
              <Link
                href="#"
                className="group flex items-center bg-white dark:bg-slate-900 hover:text-indigo-600 shadow hover:shadow-md dark:shadow-gray-800 hover:dark:shadow-gray-800 border-4 border-double border-gray-100 hover:border-indigo-600/30 dark:border-gray-800 hover:dark:border-indigo-600/50 py-1.5 px-4 rounded-full align-middle duration-500"
              >
                <i className="uil uil-award me-2 text-[18px]"></i>
                <span className="text-[18px] font-medium">News</span>
              </Link>
            </motion.li>

            <motion.li
              className="inline-block"
              whilehover={{ scale: 1.1 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
              <Link
                href="#"
                className="group flex items-center bg-white dark:bg-slate-900 hover:text-indigo-600 shadow hover:shadow-md dark:shadow-gray-800 hover:dark:shadow-gray-800 border-4 border-double border-gray-100 hover:border-indigo-600/30 dark:border-gray-800 hover:dark:border-indigo-600/50 py-1.5 px-4 rounded-full align-middle duration-500"
              >
                <i className="uil uil-bag me-2 text-[18px]"></i>
                <span className="text-[18px] font-medium">Business</span>
              </Link>
            </motion.li>

            <motion.li
              className="inline-block"
              whilehover={{ scale: 1.1 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
              <Link
                href="#"
                className="group flex items-center bg-white dark:bg-slate-900 hover:text-indigo-600 shadow hover:shadow-md dark:shadow-gray-800 hover:dark:shadow-gray-800 border-4 border-double border-gray-100 hover:border-indigo-600/30 dark:border-gray-800 hover:dark:border-indigo-600/50 py-1.5 px-4 rounded-full align-middle duration-500"
              >
                <i className="uil uil-dna me-2 text-[18px]"></i>
                <span className="text-[18px] font-medium">Entertainment</span>
              </Link>
            </motion.li>

            <motion.li
              className="inline-block"
              whilehover={{ scale: 1.1 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
              <Link
                href="#"
                className="group flex items-center bg-white dark:bg-slate-900 hover:text-indigo-600 shadow hover:shadow-md dark:shadow-gray-800 hover:dark:shadow-gray-800 border-4 border-double border-gray-100 hover:border-indigo-600/30 dark:border-gray-800 hover:dark:border-indigo-600/50 py-1.5 px-4 rounded-full align-middle duration-500"
              >
                <i className="uil uil-dribbble me-2 text-[18px]"></i>
                <span className="text-[18px] font-medium">Education</span>
              </Link>
            </motion.li>

            <motion.li
              className="inline-block"
              whilehover={{ scale: 1.1 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
              <Link
                href="#"
                className="group flex items-center bg-white dark:bg-slate-900 hover:text-indigo-600 shadow hover:shadow-md dark:shadow-gray-800 hover:dark:shadow-gray-800 border-4 border-double border-gray-100 hover:border-indigo-600/30 dark:border-gray-800 hover:dark:border-indigo-600/50 py-1.5 px-4 rounded-full align-middle duration-500"
              >
                <i className="uil uil-favorite me-2 text-[18px]"></i>
                <span className="text-[18px] font-medium">Movies</span>
              </Link>
            </motion.li>

            <motion.li
              className="inline-block"
              whilehover={{ scale: 1.1 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
              <Link
                href="#"
                className="group flex items-center bg-white dark:bg-slate-900 hover:text-indigo-600 shadow hover:shadow-md dark:shadow-gray-800 hover:dark:shadow-gray-800 border-4 border-double border-gray-100 hover:border-indigo-600/30 dark:border-gray-800 hover:dark:border-indigo-600/50 py-1.5 px-4 rounded-full align-middle duration-500"
              >
                <i className="uil uil-cube me-2 text-[18px]"></i>
                <span className="text-[18px] font-medium">Relationship</span>
              </Link>
            </motion.li>

            <motion.li
              className="inline-block"
              whilehover={{ scale: 1.1 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
              <Link
                href="#"
                className="group flex items-center bg-white dark:bg-slate-900 hover:text-indigo-600 shadow hover:shadow-md dark:shadow-gray-800 hover:dark:shadow-gray-800 border-4 border-double border-gray-100 hover:border-indigo-600/30 dark:border-gray-800 hover:dark:border-indigo-600/50 py-1.5 px-4 rounded-full align-middle duration-500"
              >
                <i className="uil uil-compass me-2 text-[18px]"></i>
                <span className="text-[18px] font-medium">Travel</span>
              </Link>
            </motion.li>

            <motion.li
              className="inline-block"
              whilehover={{ scale: 1.1 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
              <Link
                href="#"
                className="group flex items-center bg-white dark:bg-slate-900 hover:text-indigo-600 shadow hover:shadow-md dark:shadow-gray-800 hover:dark:shadow-gray-800 border-4 border-double border-gray-100 hover:border-indigo-600/30 dark:border-gray-800 hover:dark:border-indigo-600/50 py-1.5 px-4 rounded-full align-middle duration-500"
              >
                <i className="uil uil-club me-2 text-[18px]"></i>
                <span className="text-[18px] font-medium">Science</span>
              </Link>
            </motion.li>

            <motion.li
              className="inline-block"
              whilehover={{ scale: 1.1 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
              <Link
                href="#"
                className="group flex items-center bg-white dark:bg-slate-900 hover:text-indigo-600 shadow hover:shadow-md dark:shadow-gray-800 hover:dark:shadow-gray-800 border-4 border-double border-gray-100 hover:border-indigo-600/30 dark:border-gray-800 hover:dark:border-indigo-600/50 py-1.5 px-4 rounded-full align-middle duration-500"
              >
                <i className="uil uil-bright me-2 text-[18px]"></i>
                <span className="text-[18px] font-medium">Sports</span>
              </Link>
            </motion.li>

            <motion.li
              className="inline-block"
              whilehover={{ scale: 1.1 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
              <Link
                href="#"
                className="group flex items-center bg-white dark:bg-slate-900 hover:text-indigo-600 shadow hover:shadow-md dark:shadow-gray-800 hover:dark:shadow-gray-800 border-4 border-double border-gray-100 hover:border-indigo-600/30 dark:border-gray-800 hover:dark:border-indigo-600/50 py-1.5 px-4 rounded-full align-middle duration-500"
              >
                <i className="uil uil-bitcoin me-2 text-[18px]"></i>
                <span className="text-[18px] font-medium">NFT</span>
              </Link>
            </motion.li>

            <motion.li
              className="inline-block"
              whilehover={{ scale: 1.1 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
              <Link
                href="#"
                className="group flex items-center bg-white dark:bg-slate-900 hover:text-indigo-600 shadow hover:shadow-md dark:shadow-gray-800 hover:dark:shadow-gray-800 border-4 border-double border-gray-100 hover:border-indigo-600/30 dark:border-gray-800 hover:dark:border-indigo-600/50 py-1.5 px-4 rounded-full align-middle duration-500"
              >
                <i className="uil uil-bitcoin-circle me-2 text-[18px]"></i>
                <span className="text-[18px] font-medium">Stock Market</span>
              </Link>
            </motion.li>
          </ul>
        </div>
      </div>

      
    </section>
  );
}
