import React, { useState } from 'react';
import Accordion from '../../components/Accordion';
import { Fade } from "react-awesome-reveal";
import { Link } from 'react-router-dom';

export default function Faq() {
  const [accordions, setAccordion] = useState([
    {
      key: 1,
      title: 'Why should I choose Bookmesocial.com?',
      data: `Working with an influencers & Creators network is cost-effective and time-saving. Moreover, Influencer marketing helps you build customer trust and loyalty as nowadays, people prefer choosing the product based advertising method, It also helps you to create genuine customers by targeting audiences from a particular niche.`,
      isOpen: false,
    },
    {
      key: 2,
      title: 'Is Influencers & Creators Collabration Effective?',
      data: `GeeksforGeeks offers Free Tutorials,Millions of Articles, Live, Online and Classroom Courses,Frequent Coding Competitions,Webinars by Industry Experts, Internship  opportunities and Job Opportunities.`,
      isOpen: false,
    },
    {
      key: 3,
      title: 'How much does an influencer marketing campaign cost?',
      data: `Our every campaign is custom made to suit every client’s marketing requirement,so it's difficult to provide a standard pricing sheet; it varies from company to company.It will fluctuate based on a number of deliverables and factors,for example, number of influencers, number of followers they have, campaign details, labor costs, digital spend to promote the campaign etc.We will provide both standard and premium packages from which you can choose anyone based on your budget.`,
      isOpen: false,
    },
    {
      key: 4,
      title: 'Who will design the campaign ?',
      data: `That is our job to do. We use the latest tools to measure the authenticity of our influencers. We will get every detail about the influencer,from his/her number of followers,age group and location of the followers, engagement, genuine likes, quality of comment etc. We only work with authentic influencers that are famous in their particular niche.`,
      isOpen: false,
    },
    {
      key: 5,
      title: 'As a brand, do I pay influencers through Bookmesocial.com?',
      data: `Yes, we have a payment gateway that is as easy as it is secure. The way this works is you pay us once you’ve locked down your influencers and the campaign has started, and we handle the payments to each influencer after they’ve done their part.
      `,
      isOpen: false,
    },
    {
      key: 6,
      title: 'May I get a budget-friendly campaigns as a Brand ?',
      data: `The good news is yes ! All our influencer marketing campaigns are budget-friendly. Moreover, we can customize a campaign that can easily blend within your budget. To know more discuss your project with our team.
      `,
      isOpen: false,
    },
    {
      key: 7,
      title: 'Do you charge an agency fee?',
      data: `Yes, we do, but there is no charge for consulting or discussing your project with our team. Once we start working then depending on the project type and size we will charge a nominal Rs.292/- to Rs.999/- agency fee only.
      `,
      isOpen: false,
    },
    {
      key: 8,
      title: 'When will I get paid as a Partner ? mode of payment?',
      data: `Influencers/Creators will receive the payment after the successful completion of their campaign assigned by us (Bookmesocial.com).In order to receive the payment, it is necessary to fill up the bank details. Influencers will receive payment by means of Net Banking or Bank Transfer.
      `,
      isOpen: false,
    },
    {
      key: 9,
      title: 'When will I get my campaign?',
      data: `Influencers need to Sign up with us to know more about campaigns. After signing up, our team will check every influencer's profile to understand their areas of interest. On the amount basis of that, we will send them campaign proposals over time.
      `,
      isOpen: false,
    },
    {
      key: 10,
      title: 'How do I get to know about campaigns?',
      data: `Influencers will get campaign proposals directly from our end,provided they have already signed up with us. They must fill up all the mandatory fields to get notification from us.
      `,
      isOpen: false,
    },
  ]);

  const toggleAccordion = accordionkey => {
    const updatedAccordions = accordions.map(accord => {
      if (accord.key === accordionkey) {
        return { ...accord, isOpen: !accord.isOpen };
      } else {
        return { ...accord, isOpen: false };
      }
    });

    setAccordion(updatedAccordions);
  };

  return (
    <section className="relative md:py-24 px-6 pb-16 lg:py-16 flex justify-center">
      <Fade cascade>
        <div class="container relative md:mt-24 mt-16">
          <div class="grid grid-cols-1 pb-8 text-center">
            <h3 class="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
              Frequently Asked Questions
            </h3>

            <p class="text-slate-400 max-w-xl mx-auto">
              Solve your influencer marketing woes with a bookmesocial.com that makes the boring stuff simple and the fun stuff amazing.
            </p>
          </div>

          <div class="relative grid md:grid-cols-3 grid-cols-1 items-center mt-8 gap-[30px]">

            <div class="md:col-span-3">
              <div>
                {accordions.map(accordion => (
                  <Accordion
                    key={accordion.key}
                    title={accordion.title}
                    data={accordion.data}
                    isOpen={accordion.isOpen}
                    toggleAccordion={() => toggleAccordion(accordion.key)}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="container relative md:mt-24 mt-16">
            <div className="grid grid-cols-1 text-center">
              <h6 className="text-indigo-600 text-sm font-bold uppercase mb-2">
                Contact Us
              </h6>
              <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
                Have Question ? Get in touch!
              </h3>

              <p className="text-slate-400 max-w-xl mx-auto">
                Solve your influencer marketing woes with a bookmesocial.com
                that makes the boring stuff simple and the fun stuff amazing.
              </p>

              <div className="mt-6">
                <Link
                  to="/contactus"
                  className="py-2 px-4 rounded-full inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white  mt-4"
                >
                  <i class="uil uil-phone pr-2" />
                  Contact us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </section>
  );
}
