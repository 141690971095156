import { Tab } from "@headlessui/react";
import img1 from "../../assets/images/cowork/7.jpg";
import img2 from "../../assets/images/cowork/8.jpg";
import img3 from "../../assets/images/cowork/9.jpg";

export default function Influencertab() {
  return (
    <div className="px-2 py-16 sm:px-0">
      <Tab.Group>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-6">
          <div className="w-full sm:w-96">
            <Tab.List>
              <Tab className="text-left p-6 hover:bg-indigo-600 hover:text-white duration-500 shadow shadow-gray-800 rounded-md">
                <span className="block font-bold">Step 1</span>
                <span className="text-lg font-bold mt-2 block">
                  Choose Influencers/Content Creators
                </span>
                <span className="block mt-2 font-medium">
                  Fill the contact us form and our influencer marketing experts
                  will connect with you within 24 hours.
                </span>
              </Tab>
              <Tab className="text-left p-6 mt-2 hover:bg-indigo-600 hover:text-white duration-500 shadow shadow-gray-800 rounded-md">
                <span className="block font-bold">Step 2</span>
                <span className="text-lg font-bold mt-2 block">
                  Influencer Campaign Execution
                </span>
                <span className="block mt-2 font-medium">
                  After choosing the relevant influencers with respect to their
                  reach and targeted potential viewers, our team along with the
                  brand and influencers create highly engaging and shareable
                  content.
                </span>
              </Tab>
              <Tab className="text-left p-6 mt-2 hover:bg-indigo-600 hover:text-white duration-500 shadow shadow-gray-800 rounded-md">
                <span className="block font-bold">Step 3</span>
                <span className="text-lg font-bold mt-2 block">
                  Brands Get Boosted
                </span>
                <span className="block mt-2 font-medium">
                  Our leading influencer marketing platform’s content creators
                  shares inspiring stories on their respective social media
                  accounts i.e YouTube, Instagram, Twitter, Snapchat, Linkedin
                  etc.
                </span>
              </Tab>
            </Tab.List>
          </div>
          <div className=" px-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
            <Tab.Panels className="mt-2 p-1 ">
              <Tab.Panel>
                <img src={img1} alt="" className="w-full " />
              </Tab.Panel>
              <Tab.Panel>
                <img src={img2} alt="" className="w-full " />
              </Tab.Panel>
              <Tab.Panel>
                <img src={img3} alt="" className="w-full " />
              </Tab.Panel>
            </Tab.Panels>
          </div>
        </div>
      </Tab.Group>
    </div>
  );
}
