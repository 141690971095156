import React from 'react'
import { Link } from 'react-router-dom'
import { PiVideoCamera } from "react-icons/pi";
import img1 from "../../assets/images/digital/02.jpg"
import img2 from "../../assets/images/digital/01.jpg"


export default function Bestinfluencer() {
    return (
        <div>
            <section class="relative table w-full pb-12 lg:py-12">
                <div class="container relative">
                    <div class="grid md:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px]">
                        <div class="md:col-span-6">
                            <div class="md:me-6">
                                <p class="text-indigo-600 text-lg font-medium mb-4">We are a very dedicated team</p>
                                <h4 class="font-bold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5">Trusted Influencer Marketing Company </h4>
                                <p class="text-slate-400 text-lg max-w-xl">Bookmesocial.com is Influencer is India’s trusted Influencer Marketing Agency that provides a platform to connect brands with influencers to create trailblazing marketing campaigns. Fuelled by the firm belief that empowering communication by turning marketing into honest social conversations is the best way to build memorable brands,we have our name behind some of the most popular and successful influencer marketing campaigns.</p>

                                <div class="mt-6">
                                    <Link href="#!" data-type="youtube" data-id="" class="h-9 w-9 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white lightbox"><PiVideoCamera /></Link><span class="font-semibold ms-2 align-middle">Watch Now</span>
                                </div>
                            </div>
                        </div>

                        <div class="md:col-span-6">
                            <div class="grid grid-cols-12 gap-4 items-center">
                                <div class="col-span-5">
                                    <div class="grid grid-cols-1 gap-4">
                                        <img src={img1} class="shadow rounded-lg" alt="" />

                                        <div class="ms-auto">
                                            <div class="w-28 h-28 bg-indigo-600/10 rounded-lg"></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-span-7">
                                    <div class="grid grid-cols-1 gap-4">
                                        <img src={img2} class="shadow rounded-lg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

