import React from "react";
import { Fade } from "react-awesome-reveal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Support() {
    return (
        <div>
            <section className="relative flex justify-center w-full py-24 overflow-hidden bg-gradient-to-b to-transparent from-indigo-600/20 dark:from-indigo-600/40 px-4 sm:px-6 lg:px-0">
                <Fade cascade>
                    <div className="container relative bg-white rounded-xl shadow-xl p-6">
                        <div className="relative items-center gap-[30px]">
                            <div className="md:col-span-6 py-10">
                                <div className="md:me-8">
                                    <h2 className="font-bold text-center lg:leading-normal leading-normal text-3xl lg:text-4xl mb-5 text-black relative">
                                        Let's Connect
                                    </h2>
                                    <p className="text-slate-400 text-lg max-w-xl leading-relaxed lg:text-justify">
                                        We are always looking to have interesting discussions with
                                        you. Feel free to reach out to us directly if you have any
                                        ideas, comments, collaborations, or feedback.
                                    </p>
                                    <p className="text-slate-400 text-lg mt-4 max-w-xl leading-relaxed lg:text-justify">
                                        Feel free to reach out to us directly if you have any
                                        ideas, comments, collaborations, or feedback.
                                    </p>
                                    <p className="text-slate-400 text-lg mt-4 max-w-xl leading-relaxed lg:text-justify">
                                        Share your
                                        query with complete details at{" "}
                                        <a
                                            href="mailto:admin@bookmesocial.com"
                                            className="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out"
                                        >
                                            admin@bookmesocial.com
                                        </a>
                                    </p>
                                    <p className="text-slate-400 text-lg mt-4 max-w-xl leading-relaxed lg:text-justify">
                                        Thank you for showing your interest in our products and
                                        business solutions. Please fill the form below and we will
                                        get back to you within 1 business day.
                                    </p>
                                </div>
                            </div>
                            {/* <div className="md:col-span-6 gap-5">
                                <div className="relative border-[3px] text-gray-700 text-center border-gray-100 rounded-lg bg-white p-6 shadow-lg">
                                    <h3 className="font-bold text-2xl mb-4 text-blue-600">
                                        Contact Us
                                    </h3>
                                    <form action="">
                                        <div className="p-2">
                                            <input
                                                type="text"
                                                id="name"
                                                className="py-4 pe-20 ps-6 h-[50px] outline-none text-black rounded-md bg-gray-100 w-full shadow required"
                                                placeholder="Your Name*"
                                            />
                                        </div>
                                        <div className="p-2">
                                            <input
                                                type="email"
                                                id="email"
                                                className="py-4 pe-20 ps-6 h-[50px] outline-none text-black rounded-md bg-gray-100 w-full shadow required"
                                                placeholder="Your Email*"
                                            />
                                        </div>
                                        <div className="p-2">
                                            <input
                                                type="text"
                                                id="subject"
                                                className="py-4 pe-20 ps-6 h-[50px] outline-none text-black rounded-md bg-gray-100 w-full shadow required"
                                                placeholder="Subject*"
                                            />
                                        </div>
                                        <div className="p-2">
                                            <textarea
                                                id="message"
                                                className="py-4 pe-20 ps-6 h-[125px] outline-none text-black rounded-md bg-gray-100 w-full shadow required"
                                                placeholder="Your Message*"
                                                rows="5"
                                            />
                                        </div>
                                        <div className="p-4 flex justify-center">
                                            <button
                                                type="submit"
                                                className="py-2 px-4 inline-block font-semibold tracking-wide border align-middle duration-500 text-[18px] text-center bg-blue-600 hover:bg-blue-700 border-blue-600 hover:border-blue-700 text-white rounded-full w-[150px]"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </Fade>
            </section>
        </div>
    );
}