import React from "react";
import imgsrc from "../../assets/images/influencer-collage.webp";
import { Fade } from "react-awesome-reveal";

const BrandCampaign = () => {
  return (
    <section className="relative flex justify-center w-full lg:py-24 overflow-hidden bg-gradient-to-b to-transparent from-indigo-600/20 dark:from-indigo-600/40">
      <Fade cascade>
        <div className="container relative">
          <div className="relative grid md:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px]">
            <div className="md:col-span-6">
              <div className="md:me-8">
                <h4 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 text-black dark:text-white relative">
                  Easy way to{" "}
                  <span className=" px-3  rounded-full bg-indigo-600 relative inline-block">
                    <span className="relative text-[48px] text-white">manage</span>
                  </span>{" "}
                  All your Brand Campaigns               </h4>
                <p className="text-slate-400 text-lg max-w-xl">
                  Launch your campaign and benefit from our expertise on
                  designing and managing conversion-centered Your Target
                  Audience.
                </p>

                {/* <div className="subscribe-form mt-6 mb-3">
                  <form className="relative max-w-xl">
                    <input
                      type="email"
                      id="subscribe"
                      name="email"
                      className="py-4 pe-40 ps-6 w-full h-[50px] outline-none text-black dark:text-white rounded-full bg-white  shadow "
                      placeholder="Your Email Address :"
                    />
                    <button
                      type="submit"
                      className="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center absolute top-[2px] end-[3px] h-[46px] bg-indigo-600 hover:bg-indigo-700 border border-indigo-600 hover:border-indigo-700 text-white rounded-full"
                    >
                      Try it for free <i className="uil uil-arrow-right"></i>
                    </button>
                  </form>
                </div>

                <span className="text-slate-400 font-medium">
                  Looking for help?{" "}
                  <a href="contact-one.html" className="text-indigo-600">
                    Get in touch with us
                  </a>
                </span> */}
              </div>
            </div>

            <div className="md:col-span-6">
              <div className="relative">
                <img
                  src={imgsrc}
                  className="border-[3px] border-gray-100  rounded-lg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </section>
  );
};

export default BrandCampaign;
