import React from 'react';
import image from '../../assets/beautiful-woman-happy-record-video.jpg';

const EarningsSection = () => {
    return (
        <div className="flex flex-col-reverse md:flex-row items-center p-6 bg-gray-100">
            <div className="flex-1">
                <img src={image} alt="Earnings" className="w-full h-auto rounded-lg shadow-lg" />
            </div>
            <div className="flex-1 md:pl-6 text-center md:text-right">
                <h1 className="text-3xl md:text-5xl font-bold mb-4">100% Guaranteed Earnings</h1>
                <h2 className="text-xl md:text-2xl font-semibold mb-4">Get paid for every project you take on</h2>
                <p className="text-gray-700 mb-2">
                    We keep your payments safe. So you can focus on delivering your best work without worrying about payment security.
                </p>
            </div>
        </div>
    );
};

export default EarningsSection;