import React from "react";
import yola_hero from "./../assets/yola/Yolostarts hero.png";
import yolo3 from "./../assets/yola/yelo3.jpg";
import yolosta1 from "./../assets/yola/Yolostarts 1.png";
import yolo2 from "./../assets/yola/yelo2.jpg";
import yolo1 from "./../assets/yola/yelo 1.jpg";

function Yolo() {
  return (
    <div>
      <section className="relative table w-full lg:py-40 md:py-36 pt-36 pb-24 overflow-hidden bg-white dark:bg-slate-900">
        <div className="absolute inset-0 bg-repeat opacity-10 dark:opacity-60"></div>
        <div className="container relative z-1">
          <div className="relative grid lg:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px]">
            <div className="lg:col-span-7">
              <div className="lg:me-6 lg:text-start text-center">
                <h1 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-6xl mb-5">
                  Yola Starz: Drive Real Results With
                  <span
                    className="typewrite bg-gradient-to-tl to-indigo-600 from-red-600 text-transparent bg-clip-text"
                    data-period="2000"
                    data-type='[ " Nano-Influencer Marketing", "Micro-Influencer Marketing" ]'
                  >
                    {" "}
                    <span className="wrap"></span>{" "}
                  </span>
                </h1>
                <p className="text-lg max-w-xl lg:ms-0 mx-auto">
                  Welcome to Yola Starz, a data-driven influencer marketing
                  platform designed to connect brands with the right
                  influencers. With a comprehensive database of 9,000
                  influencers across South India, we specialize in Nano and
                  Micro-Influencer marketing. Our mission is to help businesses
                  enhance their brand visibility, engage authentically with
                  their audience, and drive significant conversions.
                </p>
              </div>
            </div>
            <div className="lg:col-span-5">
              <div className="relative after:content-[''] after:absolute lg:after:-top-0 after:-top-10 after:-right-32 after:w-[36rem] after:h-[36rem] after:border-2 after:border-dashed after:border-slate-200 dark:after:border-slate-700 after:rounded-full after:animate-[spin_120s_linear_infinite] after:-z-1 before:content-[''] before:absolute lg:before:-top-24 before:-top-36 before:-right-56 before:w-[48rem] before:h-[48rem] before:border-2 before:border-dashed before:border-slate-200 dark:before:border-slate-700 before:rounded-full before:animate-[spin_240s_linear_infinite] before:-z-1">
                <div className="relative after:content-[''] after:absolute lg:after:-top-0 after:-top-10 after:-right-40 after:w-[36rem] after:h-[36rem] after:bg-gradient-to-tl after:to-indigo-600/30  after:from-red-600/30 dark:after:to-indigo-600/50 dark:after:from-red-600/50 after: after:blur-[200px] after:rounded-full after:-z-1">
                  <img
                    src={yola_hero}
                    className="lg:max-w-none lg:ms-14"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
              Earn as a Social Media Content Creator on Yola Starz
            </h3>

            <p className="text-slate-400 max-w-xl mx-auto">
              Are you a creative social media content creator? Yola Starz offers
              you the opportunity to monetize your creativity and collaborate
              with iconic brands. Here’s what you can expect:
            </p>
          </div>

          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
            <div className="group relative">
              <div className="relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                <div className="absolute inset-0 bg-slate-900/30 opacity-0 group-hover:opacity-100 duration-500 ease-in-out"></div>
              </div>

              <div className="mt-6">
                <a
                  href="#"
                  className="text-xl font-semibold hover:text-indigo-600 duration-500"
                >
                  Full Support
                </a>

                <p className="text-slate-400 mt-4">
                  Our Influencer Success Managers are dedicated to coaching you
                  and ensuring your success. They will assist with onboarding
                  and campaign execution, providing the guidance you need to
                  thrive.
                </p>
              </div>
            </div>

            <div className="group relative">
              <div className="relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                <div className="absolute inset-0 bg-slate-900/30 opacity-0 group-hover:opacity-100 duration-500 ease-in-out"></div>
              </div>

              <div className="mt-6">
                <a
                  href="#"
                  className="text-xl font-semibold hover:text-indigo-600 duration-500"
                >
                  Rewards for Creativity
                </a>

                <p className="text-slate-400 mt-4">
                  Be rewarded for your innovative content and work with leading
                  brands that value your unique voice.
                </p>
              </div>
            </div>

            <div className="group relative">
              <div className="relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                <div className="absolute inset-0 bg-slate-900/30 opacity-0 group-hover:opacity-100 duration-500 ease-in-out"></div>
              </div>
              <div className="mt-6">
                <a
                  href="#"
                  className="text-xl font-semibold hover:text-indigo-600 duration-500"
                >
                  Personalized Assistance
                </a>

                <p className="text-slate-400 mt-4">
                  From the moment you join, our team is here to support you,
                  ensuring a seamless and productive experience.
                </p>

                <div className="mt-4">
                  <a
                    href="#"
                    className="hover:text-indigo-600 duration-500 ease-in-out font-semibold"
                  >
                    <span className="hidden group-hover:inline-block">
                      Read More
                    </span>{" "}
                    <i className="uil uil-arrow-right align-middle text-lg"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container relative md:mt-24 mt-16">
        <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
          <div className="md:col-span-6 order-1 md:order-2">
            <div className="lg:ms-8">
              <img src={yolosta1} alt="" />
            </div>
          </div>

          <div className="md:col-span-6 order2 md:order-1">
            <div className="lg:me-5">
              <h6 className="text-indigo-600 text-sm font-bold uppercase mb-2">
                Why Join Yola Starz?
              </h6>
              <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
                Yola Starz is committed to driving real results through
                strategic nano and micro-influencer marketing. Let’s connect and
                grow together!
              </h3>
              <ul className="list-none text-slate-400 my-6">
                <li className="mb-1 flex">
                  <i className="uil uil-check-circle text-indigo-600 text-xl me-2"></i>
                  Growth Opportunities: Enhance your portfolio and grow your
                  influence by working on diverse campaigns.
                </li>
                <li className="mb-1 flex">
                  <i className="uil uil-check-circle text-indigo-600 text-xl me-2"></i>
                  Comprehensive Support: Enjoy the benefits of a dedicated
                  support team focused on your growth and success.
                </li>
                <li className="mb-1 flex">
                  <i className="uil uil-check-circle text-indigo-600 text-xl me-2"></i>{" "}
                  Access to Top Brands: Collaborate with renowned brands that
                  are eager to leverage your influence.
                </li>
              </ul>
              <a
                href="#"
                className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600/5 hover:bg-indigo-600 border-indigo-600/10 hover:border-indigo-600 text-indigo-600 hover:text-white rounded-full"
              >
                Influencer Registration
              </a>
            </div>
          </div>
        </div>
      </div>

      <section className="relative bg-gray-50 dark:bg-slate-800 md:py-24 py-16">
        <div className="container relative">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div className="lg:col-span-5 md:col-span-6">
              <div className="grid grid-cols-12 gap-6 items-center">
                <div className="col-span-6">
                  <div className="grid grid-cols-1 gap-6">
                    <img
                      src={yolo3}
                      className="shadow rounded-md"
                      alt=""
                    />
                    <img
                      src={yolo2}
                      className="shadow rounded-md"
                      alt=""
                    />
                  </div>
                </div>

                <div className="col-span-6">
                  <div className="grid grid-cols-1 gap-6">
                    <img
                      src={yolo1}
                      className="shadow rounded-md"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="lg:col-span-7 md:col-span-6">
              <div className="lg:ms-5">
                <div className="flex mb-4">
                  <span className="text-indigo-600 text-2xl font-bold mb-0">
                    <span
                      className="counter-value text-6xl font-bold"
                      data-target="9000"
                    >
                      9
                    </span>
                    +
                  </span>
                  <span className="self-end font-semibold ms-2">
                    Influencers <br /> across South India
                  </span>
                </div>

                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
                  Why Nano-influencer?{" "}
                </h3>

                <p className="text-slate-400 max-w-xl">
                  Nano-influencer marketing is a game-changer for businesses
                  aiming to build strong connections with their target market.
                  Here’s why it’s so effective:
                </p>
                <ul className="list-none text-slate-400 my-6">
                  <li className="mb-1 flex">
                    <i className="uil uil-check-circle text-indigo-600 text-xl me-2"></i>
                    Authentic Engagement: Nano-influencers have highly engaged
                    followers who trust their opinions, making them perfect for
                    authentic brand endorsements.
                  </li>
                  <li className="mb-1 flex">
                    <i className="uil uil-check-circle text-indigo-600 text-xl me-2"></i>
                    Cost-Effective: Compared to macro-influencers,
                    nano-influencers offer higher engagement rates at a fraction
                    of the cost..
                  </li>
                  <li className="mb-1 flex">
                    <i className="uil uil-check-circle text-indigo-600 text-xl me-2"></i>{" "}
                    ANiche Targeting: With nano-influencers, brands can target
                    specific niches and communities, ensuring their message
                    reaches the most relevant audience.
                  </li>
                </ul>
                <div className="mt-6">
                  <a
                    href=""
                    className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-2"
                  >
                    <i className="uil uil-airplay"></i> Brand Registration
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Yolo;
