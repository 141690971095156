export default function Accordion(props) {
    return (
        <div className="border rounded-md mb-1">
            <button
                className="items-center p-4 w-full font-medium text-start   
                text-black hover:bg-gray-300 transition duration-300"
                onClick={props.toggleAccordion}
            >
                {props.title}
                <span className={`float-right transform ${props.isOpen ?
                    'rotate-180' : 'rotate-0'}  
                                 transition-transform duration-300`}>
                    &#9660;
                </span>
            </button>
            {props.isOpen && (
                <div className="p-2 w-[36rem] max-sm:w-[300px] bg-white text-slate-400 text-justify">
                    {props.data}
                </div>
            )}
        </div>
    );
};