import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import About from "./Pages/About";
import Contactus from "./Pages/Contactus";
import Brandlogin from "./Pages/Auth/Brandlogin";
import Brandregister from "./Pages/Auth/Brandregister";
import Creatorregister from "./Pages/Auth/Creatorregister";
import Yolo from "./Pages/Yolo";
import Support from "./Pages/Support";
import Creator_Dashboard from "./Pages/Auth/Creator_Dashboard";

function AdminRedirect() {
  window.location.replace("https://admindashboard.bookmesocial.com");
  return null;
}

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contactus" element={<Contactus />} />
          <Route path="/brandlogin" element={<Brandlogin />} />
          <Route path="/brandregister" element={<Brandregister />} />
          <Route path="/creatorregister" element={<Creatorregister />} />
          <Route path="/dashboard" element={<Creator_Dashboard />} />
          <Route path="/support" element={<Support />} />
          <Route path="/admin" element={<AdminRedirect />} />
          <Route path="/yolastarz" element={<Yolo />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
