import React from "react";
import img1 from "../../assets/images/three-influencers.webp"
import { FaRegCheckCircle } from "react-icons/fa";
export default function Topgoals() {
  return (
    <section className="relative py-2 px-9 flex justify-center">
      <div className="container relative md:mt-16 mt-12">
        <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
          <div className="md:col-span-6 order-1 md:order-2">
            <div className="lg:ms-8">
              <img src={img1} alt="" />
            </div>
          </div>

          <div className="md:col-span-6 order2 md:order-1">
            <div className="lg:me-5">
              <h6 className="text-indigo-600 text-sm font-bold uppercase mb-2">
                Why Bookmesocial.com ?
              </h6>
              <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
                The top goals of influencer marketing for businesses comprise
                increasing brand awareness, reaching new audiences, new product
                launches, and generating sales and conversions.
              </h3>

              <ul className="list-none text-slate-400 my-6">
                <li className="mb-1 flex">
                  <i className=" text-indigo-600 text-xl me-2"><FaRegCheckCircle /></i>
                  Brands share us their marketing goals, we plan, strategize
                  their campaign
                </li>
                <li className="mb-1 flex">
                  <i className=" text-indigo-600 text-xl me-2"><FaRegCheckCircle /></i>{" "}
                  We then leverage our comprehensive network of influencers to
                  find the right fit for your brand who can give maximum reach
                  sales.
                </li>
                <li className="mb-1 flex">
                  <i className=" text-indigo-600 text-xl me-2"><FaRegCheckCircle /></i>{" "}
                  Our astute team will help you to accomplish goals such as
                  accelerated brand awareness, pivotal content strategy,
                  escalated engagement, lead generation and much more.
                </li>
              </ul>

              {/* <a
                href="contact-one.html"
                className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600/5 hover:bg-indigo-600 border-indigo-600/10 hover:border-indigo-600 text-indigo-600 hover:text-white rounded-full"
              >
                Start Your Campaign Today!{" "}
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
