import React from 'react';
import Bestinfluencer from './About/Bestinfluencer';
import Whoarewe from './About/Whoarewe';
import Brandtab from './About/Brandtab';
import Ourservices from './Home/Ourservices';
import EarningsSection from './Home/EarningsSection';
// import Wanttobeinfluencer from './About/Wanttobeinfluencer';

export default function About() {
  return (
    <div>
      <Bestinfluencer />
      <Whoarewe />
      <Brandtab />
      <EarningsSection />
      <Ourservices />
      {/* <Wanttobeinfluencer /> */}
    </div>
  )
}
