import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import {Navigation, FreeMode} from 'swiper/modules';
import R2 from '../../assets/r2.jpeg';
import R3 from '../../assets/r3.jpeg';
import R4 from '../../assets/r4.jpeg';
import R5 from '../../assets/r5.jpeg';
import {FaQuoteLeft} from 'react-icons/fa';

export default function Testimonal () {
  return (
    <div>
      <section class="relative md:py-24 pb-16 overflow-hidden">
        <div class="container relative">
          <div class="grid grid-cols-1 pb-8 text-center">
            <h3 class="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
              What Our Users Say
            </h3>
            <h3 class="mb-6 mt-8 md:text-4xl text-3xl md:leading-normal leading-normal font-bold">
              The best customer relationship
              {' '}
              <br />
              {' '}
              management platform for just
              {' '}
              <br /> about{' '}
              <span class="after:absolute after:end-0  after:start-0  after:bottom-1 after:lg:h-3 after:h-2 after:w-auto after:rounded-md after:bg-indigo-600/30 relative text-indigo-600">
                everything.
              </span>
            </h3>
          </div>
        </div>
        <Swiper
          breakpoints={{
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 2,
            },
            1279: {
              slidesPerView: 3,
            },
            1536: {
              slidesPerView: 3,
            },
          }}
          spaceBetween={0}
          freeMode={true}
          navigation={{
            nextEl: '.swiper-button-next-1',
            prevEl: '.swiper-button-prev-1',
            hiddenClass: 'swiper-button-hidden',
          }}
          pagination={{
            clickable: true,
          }}
          modules={[FreeMode, Navigation]}
          className="mySwiper max-w-6xl mt-10 "
        >
          <SwiperSlide className=" p-5">
            <div class="tiny-slide text-center">
              <div class="cursor-e-resize">
                <div class="content relative rounded shadow dark:shadow-gray-800 m-2 p-6 bg-white dark:bg-slate-900 before:content-[''] before:absolute before:start-1/2 before:-bottom-[4px] before:box-border before:border-8 before:rotate-[45deg] before:border-t-transparent before:border-e-white dark:before:border-e-slate-900 before:border-b-white dark:before:border-b-slate-900 before:border-s-transparent before:shadow-testi dark:before:shadow-gray-700 before:origin-top-left">
                  <FaQuoteLeft className="text-indigo-600 text-[48px] flex justify-center" />
                  <p class="text-slate-400">
                    " Bookme Social is the best influencer platform that helped me find high-quality influencers. They also took care of the campaign, which was truly amazing, and their customer support was excellent."
                  </p>
                </div>
                <div class="text-center mt-5">
                  <img
                    src="https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcRf6nVW2k9Y03N7FjETDzVjjpy8GZpPmRXgPJn78EULhDrBudmK"
                    class="h-14 w-14 rounded-full shadow-md mx-auto"
                    alt=""
                  />
                  <h6 class="mt-2 font-semibold">NAS Suresh Krishna -  Founder</h6>
                  <span class="text-slate-400 text-sm">
                    Wolfertech
                  </span>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className=" p-5">
            <div class="tiny-slide text-center">
              <div class="cursor-e-resize">
                <div class="content relative rounded shadow dark:shadow-gray-800 m-2 p-6 bg-white dark:bg-slate-900 before:content-[''] before:absolute before:start-1/2 before:-bottom-[4px] before:box-border before:border-8 before:rotate-[45deg] before:border-t-transparent before:border-e-white dark:before:border-e-slate-900 before:border-b-white dark:before:border-b-slate-900 before:border-s-transparent before:shadow-testi dark:before:shadow-gray-700 before:origin-top-left">
                  <FaQuoteLeft className="text-indigo-600 text-[48px] flex justify-center" />
                  <p class="text-slate-400">
                    " Fellowfounders has been associated with Bookmesocial.com for more than a year now. Since then, we have worked with influencers of different genre and we absolutely love the efforts put into our campaigns.I highly recommend Bookmeaocial.com as one of best platform to work with,. "
                  </p>
                </div>
                <div class="text-center mt-5">
                  <img
                    src={R2}
                    class="h-14 w-14 rounded-full shadow-md mx-auto"
                    alt=""
                  />
                  <h6 class="mt-2 font-semibold">Joshua - Founder</h6>
                  <span class="text-slate-400 text-sm">
                    Fellowfounders
                  </span>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className=" p-5">
            <div class="tiny-slide text-center">
              <div class="cursor-e-resize">
                <div class="content relative rounded shadow dark:shadow-gray-800 m-2 p-6 bg-white dark:bg-slate-900 before:content-[''] before:absolute before:start-1/2 before:-bottom-[4px] before:box-border before:border-8 before:rotate-[45deg] before:border-t-transparent before:border-e-white dark:before:border-e-slate-900 before:border-b-white dark:before:border-b-slate-900 before:border-s-transparent before:shadow-testi dark:before:shadow-gray-700 before:origin-top-left">
                  <FaQuoteLeft className="text-indigo-600 text-[48px] flex justify-center" />
                  <p class="text-slate-400">
                    " I genuinely appreciate the efforts of Bookmesocial team for us. The way they handled our campaigns is really commendable and so is their diligence.Some of our issues were resolved even past midnight which was truly amazing.Glad to have Bokkmesocial as a partner.Looking forward to more partnerships, "
                  </p>
                </div>
                <div class="text-center mt-5">
                  <img
                    src={R4}
                    class="h-14 w-14 rounded-full shadow-md mx-auto"
                    alt=""
                  />
                  <h6 class="mt-2 font-semibold">Thomas - Co-Founder</h6>
                  <span class="text-slate-400 text-sm">
                    Dailylife
                  </span>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className=" p-5">
            <div class="tiny-slide text-center">
              <div class="cursor-e-resize">
                <div class="content relative rounded shadow dark:shadow-gray-800 m-2 p-6 bg-white dark:bg-slate-900 before:content-[''] before:absolute before:start-1/2 before:-bottom-[4px] before:box-border before:border-8 before:rotate-[45deg] before:border-t-transparent before:border-e-white dark:before:border-e-slate-900 before:border-b-white dark:before:border-b-slate-900 before:border-s-transparent before:shadow-testi dark:before:shadow-gray-700 before:origin-top-left">
                  <FaQuoteLeft className="text-indigo-600 text-[48px] flex justify-center" />
                  <p class="text-slate-400">
                    " Cryptographic Solutions worked with  Bookmesocial.com team for an influencer program that involved big influencers.The campaigns were well executed and handled by the team.Really appreciated the proactive communication by them. "
                  </p>
                </div>
                <div class="text-center mt-5">
                  <img
                    src={R3}
                    class="h-14 w-14 rounded-full shadow-md mx-auto"
                    alt=""
                  />
                  <h6 class="mt-2 font-semibold">Sarath</h6>
                  <span class="text-slate-400 text-sm">Cryptograpic Solutions</span>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className=" p-5">
            <div class="tiny-slide text-center">
              <div class="cursor-e-resize">
                <div class="content relative rounded shadow dark:shadow-gray-800 m-2 p-6 bg-white dark:bg-slate-900 before:content-[''] before:absolute before:start-1/2 before:-bottom-[4px] before:box-border before:border-8 before:rotate-[45deg] before:border-t-transparent before:border-e-white dark:before:border-e-slate-900 before:border-b-white dark:before:border-b-slate-900 before:border-s-transparent before:shadow-testi dark:before:shadow-gray-700 before:origin-top-left">
                  <FaQuoteLeft className="text-indigo-600 text-[48px] flex justify-center" />
                  <p class="text-slate-400">
                    " Bookmesocial team played an instrumental role in successfully expanding our influencer marketing efforts.They were able to identify new, relevant creators in multiple verticals, and they managed the entire process from beginning to end. I can always count on them to communicate exactly what’s going on, which is super important when working with Influencers.Always reliable and professional. "
                  </p>
                </div>
                <div class="text-center mt-5">
                  <img
                    src={R5}
                    class="h-14 w-14 rounded-full shadow-md mx-auto"
                    alt=""
                  />
                  <h6 class="mt-2 font-semibold" >Ari </h6>
                  <span class="text-slate-400 text-sm"> Ari Sports Academy</span>
                </div>
              </div>
            </div>
          </SwiperSlide>

        </Swiper>
      </section>
    </div>
  );
}
